<template>
  <div class="container">
    <Sidebar />

    <router-view class="view"></router-view>

    <alert></alert>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Alert from "../components/Alert.vue";

export default {
  components: { Sidebar, Alert }
};
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.view {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd3);
  gap: var(--lengthMd3);
  padding: var(--lengthMd1);
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .view {
    padding: var(--lengthMd3);
  }
}

@media only screen and (min-width: 1140px) {
  .view {
    padding: var(--lengthLg1);
  }
}
</style>
