<template>
  <transition name="alert">
    <div v-if="alert.state" class="alert" :class="typeClass">
      <span class="alert__icon">
        <font-awesome-icon :icon="stateIcon" />
      </span>

      <span>{{ alert.message }}</span>

      <button class="alert__close-btn" @click="clearAlert">
        <font-awesome-icon icon="times" />
      </button>
    </div>
  </transition>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    errorIcon: faExclamationCircle,
    successIcon: faCheckCircle
  }),

  watch: {
    "alert.state"(val) {
      if (val) {
        setTimeout(() => this.clearAlert(), 5000);
      } else return;
    }
  },

  computed: {
    ...mapState(["alert"]),

    stateIcon() {
      switch (this.alert.state) {
        case "success":
          return this.successIcon;
        case "error":
          return this.errorIcon;
        default:
          return this.successIcon;
      }
    },

    typeClass() {
      switch (this.alert.state) {
        case "success":
          return "alert--success";

        case "error":
          return "alert--danger";
      }
      return "";
    }
  },

  methods: {
    ...mapMutations(["setAlert"]),

    clearAlert() {
      this.setAlert({
        state: "",
        message: ""
      });
    }
  }
};
</script>

<style scoped>
.alert {
  position: fixed;
  top: 0;
  right: 0;
  margin: var(--lengthMd1);
  padding: var(--lengthMd1);
  display: flex;
  align-items: center;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
  color: white;
  font-weight: var(--medium);
  background-color: var(--fontColor1);
  border-radius: var(--lengthSm1);
  box-shadow: 0 0 5px 1px rgba(000, 000, 000, 0.1);
  z-index: 9998;
}

.alert__close-btn {
  color: var(--fontColor2);
}

.alert--danger .alert__icon {
  color: var(--dangerColor);
}

.alert--success .alert__icon {
  color: var(--successColor);
}

.alert-enter,
.alert-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}

.alert-enter-active,
.alert-leave-active {
  transition: transform 300ms, opacity 300ms;
}
</style>
